import React from 'react'

const AnimateMarquee = (props) => {
    return (
        <div className="animate-marque-sec">
            <div className="animate-marque">
                <div className="marquee">
                    <div className="track">
                        <div className="content">
                            <h1>
                                <span>Midmeal</span>
                                <span>Surgical</span>
                                <span>Resources</span>
                                <span>Digital</span>
                                <span>Product</span>
                                <span>Midmeal</span>
                                <span>Surgical</span>
                                <span>Resources</span>
                                <span>Digital</span>
                                <span>Product</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnimateMarquee;
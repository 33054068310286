import React, { Fragment, useState } from "react";
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { Button, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart, removeFromCompareList } from "../../store/actions/action";
import { getCompareList } from "../../utils";
import Footer from "../../components/footer/Footer";




const ComparePage = ({ compareList, removeFromCompareList, symbol, addToCart }) => {

  let ratingBack = [];
  let i = 0;

  for (i = 0; i < 5; i++) {
    ratingBack.push(<li key={i}><i className="icon-star" /></li>)
  }

  const [qty] = useState(1);


  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={'Compare'} pagesub={"Compare"} />
      <Grid className="compare-section separator-padding">
        <Grid className="container">
          <Grid item xs={12}>
            {compareList.length > 0 ?
              <div className="compare-table">
                <Table className={`comapare-wrap table-responsive`} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th">Image</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <Grid className="cmpProdThumb">
                            <img src={item.proImg} alt="product thumb" />
                          </Grid>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Name</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <h5><Link to={`/product-single/${item.slug}`}>{item.title}</Link></h5>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Price</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <p>{symbol}{item.price}</p>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Size</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <p>{item.size}</p>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Category</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <p>{item.category}</p>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Availability</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <p>{item.stock}</p>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Ratings</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <Grid className="pro_rating pureRating">
                            <List className="ratingBack">
                              {ratingBack}
                            </List>
                          </Grid>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Purchase</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <Button className="cBtn cBtnSmall cBtnBlack cBtnRadius"
                            onClick={() => addToCart(item, qty)}>Add
                            to Cart</Button>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">Remove</TableCell>
                      {compareList.map(item => (
                        <TableCell key={item.id}>
                          <Grid className="cmpProdThumb">
                            <Button onClick={() => removeFromCompareList(item)}><i className="icon-icon_close_2"></i>remove</Button>
                          </Grid>
                        </TableCell>
                      ))}
                    </TableRow>

                  </TableBody>
                </Table>
              </div>
              : <div className="not-found">
                  <i className="icon-left-and-right-arrows-1"></i>
                 <p className="noProdFound">Nothing to compare!</p>
              </div>
            }
          </Grid>
        </Grid>
      </Grid>
      <Footer/>
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    compareList: getCompareList(state.compareList.compare_list),
    symbol: state.data.symbol
  }
};

export default connect(mapStateToProps, { removeFromCompareList, addToCart })(ComparePage);
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';

const Feaures = [
    {
        id: '1',
        icon: 'icon-shipped',
        title: "In-Store Pickup",
        des: "I have been a loyal customer of this auto parts company for years .",
    },
    {
        id: '2',
        icon: 'icon-product',
        title: "Free Shipping Over $20",
        des: "I have been a loyal customer of this auto parts company for years .",
    },
    {
        id: '3',
        icon: 'icon-placeholder',
        title: "Store Locator",
        des: "I have been a loyal customer of this auto parts company for years .",
    },
    {
        id: '4',
        icon: 'icon-vehicle',
        title: "Free Servicing",
        des: "I have been a loyal customer of this auto parts company for years .",
    },
    {
        id: '5',
        icon: 'icon-quality',
        title: "100% Quality Product",
        des: "I have been a loyal customer of this auto parts company for years .",
    },
    {
        id: '6',
        icon: 'icon-shopping-cart',
        title: "Speed Perks",
        des: "I have been a loyal customer of this auto parts company for years .",
    }
]

const FeaturesSection = (props) => {

    return (
        <section className="features-section separator-padding">
            <div className="container sortable-gallery">
                <SectionTitle sTitle={'Know what you pay for'} sClass={'heading-title-s2'} />
                <div className="features-wrap">
                    <div className="row">
                        {
                            Feaures.map((feaures, cat) => (
                                <div className="col-lg-4 col-md-6 col-12" key={cat}>
                                    <div className="features-item">
                                        <div className="features-icon">
                                            <div className="icon">
                                                <i className={feaures.icon}></i>
                                            </div>
                                        </div>
                                        <div className="features-text">
                                            <h2>{feaures.title}</h2>
                                            <p>{feaures.des}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesSection;
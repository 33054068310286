import React from 'react'
import abImg from '../../images/about/img-1.jpg'
import abImg2 from '../../images/about/img-2.jpg'
import abImg3 from '../../images/about/icon.png'


const About = (props) => {

    return (
        <section className="about-section separator-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-img-wrap">
                            <div className="about-img-top">
                                <img src={abImg} alt=""/>
                            </div>
                            <div className="about-img-bottom">
                                <img src={abImg2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-text">
                            <div className="about-icon">
                                <img src={abImg3} alt=""/>
                            </div>
                            <h2>Medimela Create Unique High-quality Product From The Finest Development Since 2000.</h2>
                            <p>I have been a loyal customer of this auto parts company for years and I cannot recommend them enough. Their extensive selection of high-quality parts and accessories, combined with their competitive prices and exceptional customer service, make them the go-to source for all of my automotive needs</p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="about-features">
                                        <i className="icon-medical"></i>
                                        <h3>Doctors</h3>
                                        <p>I have been a loyal customer of this auto parts company for years .</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="about-features">
                                        <i className="icon-medicine"></i>
                                        <h3>Equipment's</h3>
                                        <p>I have been a loyal customer of this auto parts company for years .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
import React from 'react';
import { Link } from 'react-router-dom'
import bImg1 from '../../images/banner/img-3.png'
import bImg2 from '../../images/banner/img-1.png'
import bImg3 from '../../images/banner/img-5.png'
import bImg4 from '../../images/banner/img-4.png'
import shape1 from '../../images/banner/frame-1.png'
import shape2 from '../../images/banner/blur-shape.png'
import shape3 from '../../images/banner/greencard-bg.png'
import SectionTitle from '../SectionTitle/SectionTitle';

const Category = [
    {
        id: '1',
        icon: 'icon-skincare',
        title: "Beauty",
    },
    {
        id: '2',
        icon: 'icon-food',
        title: "Grocery",
    },
    {
        id: '3',
        icon: 'icon-medicine',
        title: "Pharmacy",
    },
    {
        id: '4',
        icon: 'icon-mask-1',
        title: "Surgical Mask",
    },
    {
        id: '5',
        icon: 'icon-medical',
        title: "Medkits",
    },
    {
        id: '6',
        icon: 'icon-food',
        title: "Medicine",
    },
    {
        id: '7',
        icon: 'icon-stethoscope',
        title: "Stethoscope",
    },
    {
        id: '8',
        icon: 'icon-product',
        title: "Gift Box",
    },
]

const Categorys2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <section className="featured-section style-2 separator-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <SectionTitle sTitle={'Featured categorys'} />
                    </div>
                    <div className="col-lg-6">
                        <div className="view-btn">
                            <Link onClick={ClickHandler} to="/shop">View More</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="featured-category-wrap">
                            <div className="row">
                                {
                                    Category.map((category, cat) => (
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-12" key={cat}>
                                            <div className="featured-item" >
                                                <div className="icon">
                                                    <i className={category.icon}></i>
                                                </div>
                                                <div className="text">
                                                    <h2><Link onClick={ClickHandler} to={'/shop'}>{category.title}</Link></h2>
                                                    <span>{category.id} Product</span>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="category-banner">
                            <div className="offer-banner-item red-banner">
                                <div className="offer-image">
                                    <img src={bImg1} alt=""/>
                                </div>
                                <div className="text">
                                    <h4>Upto 25% Off</h4>
                                    <h2>Hand <small>sanitizer</small></h2>
                                    <p>I have been a loyal customer of this auto parts company for years.</p>
                                    <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                                </div>
                                <div className="frame"><img src={shape1} alt=""/></div>
                                <div className="blur-shape"><img src={shape2} alt=""/></div>
                            </div>
                            <div className="offer-banner-item blue-card">
                                <div className="offer-image">
                                    <img src={bImg2} alt=""/>
                                </div>
                                <div className="text">
                                    <span>-10% OFF</span>
                                    <h2>Hand <small>sanitizer</small></h2>
                                    <p>I have been a loyal customer of this auto parts company for years.</p>
                                    <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                                </div>
                                <div className="frame"><img src={shape1} alt=""/></div>
                                <div className="blur-shape"><img src={shape2} alt=""/></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="category-banner">
                            <div className="offer-banner-item green-card">
                                <div className="text">
                                    <span>-10% OFF</span>
                                    <h2>New Medical Equipment Stethoscope 2024</h2>
                                    <p>I have been a loyal customer of this auto parts company for years.</p>
                                    <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                                </div>
                                <div className="offer-image">
                                    <img src={bImg3} alt=""/>
                                </div>
                                <div className="frame"><img src={shape3} alt=""/></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="offer-banner-item yellow-banner">
                            <div className="offer-image">
                                <img src={bImg4} alt=""/>
                            </div>
                            <div className="text">
                                <h4>Upto 25% Off</h4>
                                <h2>Hand <small>sanitizer</small></h2>
                                <p>I have been a loyal customer of this auto parts company for years.</p>
                                <Link onClick={ClickHandler} to="/shop" className="small-btn-style primary">Shop Now</Link>
                            </div>
                            <div className="frame"><img src={shape1} alt=""/></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Categorys2;
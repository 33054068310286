import React, { useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import ViewModal from "../Modal";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import erimg from '../../images/left-img.png'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}


const LatestProduct = ({ products, addToCartProduct, addToWishListProduct, addToCompareListProduct }) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({});

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <section className="latest-product-section separator-padding pt-0">
            <div className="container sortable-gallery">
                <div className="latest-product-title">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <SectionTitle sTitle={'Our Latest Products'} />
                        </div>
                        <div className="col-lg-8">
                            <div className="products-filters">
                                <ul>
                                    <Tabs value={value}
                                        classes={{
                                            root: 'prdTab',
                                            indicator: 'prdTabIndicator'
                                        }}
                                        onChange={handleChange}
                                        aria-label="simple tabs example">
                                        <Tab label="Accessories" />
                                        <Tab label="Blood Pressure" />
                                        <Tab label="Equipments" />
                                    </Tabs>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 order-lg-1 order-2">
                        <div className="latest-product-left-sidebar">
                            <div className="left-sidebar-text">
                                <div className="small-heading-title">
                                    <span>100% PureHand</span>
                                    <h3>Amandean Wild Caught</h3>
                                    <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                                </div>
                            </div>
                            <div className="left-sidebar-img">
                                <div className="discount">
                                    <span>Discount</span>
                                    <h3>50%</h3>
                                </div>
                                <img src={erimg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 order-lg-2 order-1">
                        <div className="product-wrap">
                            <TabPanel value={value} index={0}>
                                <div className="row">
                                    {products.length > 0 &&
                                        products.slice(0, 6).map((product, pitem) => (
                                            <div className="col-xl-4 col-lg-6 col-sm-6 col-12" key={pitem}>
                                                <div className="product-item">
                                                    <div className="product-image">
                                                        <img src={product.proImg} alt="" />
                                                        <ul className="tag-wrap">
                                                            {product.badge1 ? <li className={`tag ${product.badgeClass}`}>{product.badge1}</li> : ""}
                                                            {product.badge2 ? <li className={`tag ${product.badgeClass2}`}>{product.badge2}</li> : ""}
                                                        </ul>
                                                        <ul className="info-wrap">
                                                            <li>
                                                                <button
                                                                    data-tooltip-id="wish-tooltip" data-tooltip-content="Add to Wishlist"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => addToWishListProduct(product)}
                                                                >
                                                                    <i className="icon-heart"></i>
                                                                </button>
                                                                <Tooltip id="wish-tooltip" />
                                                            </li>
                                                            <li>
                                                                <button
                                                                    data-tooltip-id="compare-tooltip" data-tooltip-content="Add to Compare"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => addToCompareListProduct(product)}
                                                                >
                                                                    <i className="icon-left-and-right-arrows-1"></i>
                                                                </button>
                                                                <Tooltip id="compare-tooltip" />
                                                            </li>
                                                            <li data-bs-toggle="modal" data-bs-target="#modal-cart-view">
                                                                <button
                                                                    data-tooltip-id="view-tooltip" data-tooltip-content="Quick View"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => handleClickOpen(product)}
                                                                >
                                                                    <i className="icon-open-eye-1"></i>
                                                                </button>
                                                                <Tooltip id="view-tooltip" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="product-info">
                                                        <h2><Link onClick={ClickHandler} to={`/product-single/${product.slug}`}>{product.title}</Link></h2>
                                                        <div className="rating-product">
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                        </div>
                                                        <div className="price">
                                                            <span className="present-price">${product.price}</span>
                                                            <del className="old-price">${product.delPrice}</del>
                                                            <button
                                                                className="cart-btn"
                                                                data-tooltip-id="cart-tooltip" data-tooltip-content="add to cart"
                                                                data-tooltip-place="left"
                                                                onClick={() => addToCartProduct(product)}
                                                            >
                                                                <i className="icon-cart"></i>
                                                            </button>
                                                            <Tooltip id="cart-tooltip" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="row">
                                    {products.length > 0 &&
                                        products.slice(6, 12).map((product, pitem) => (
                                            <div className="col-xl-4 col-lg-6 col-sm-6 col-12" key={pitem}>
                                                <div className="product-item">
                                                    <div className="product-image">
                                                        <img src={product.proImg} alt="" />
                                                        <ul className="tag-wrap">
                                                            {product.badge1 ? <li className={`tag ${product.badgeClass}`}>{product.badge1}</li> : ""}
                                                            {product.badge2 ? <li className={`tag ${product.badgeClass2}`}>{product.badge2}</li> : ""}
                                                        </ul>
                                                        <ul className="info-wrap">
                                                            <li>
                                                                <button
                                                                    data-tooltip-id="wish-tooltip" data-tooltip-content="Add to Wishlist"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => addToWishListProduct(product)}
                                                                >
                                                                    <i className="icon-heart"></i>
                                                                </button>
                                                                <Tooltip id="wish-tooltip" />
                                                            </li>
                                                            <li>
                                                                <button
                                                                    data-tooltip-id="compare-tooltip" data-tooltip-content="Add to Compare"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => addToCompareListProduct(product)}
                                                                >
                                                                    <i className="icon-left-and-right-arrows-1"></i>
                                                                </button>
                                                                <Tooltip id="compare-tooltip" />
                                                            </li>
                                                            <li data-bs-toggle="modal" data-bs-target="#modal-cart-view">
                                                                <button
                                                                    data-tooltip-id="view-tooltip" data-tooltip-content="Quick View"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => handleClickOpen(product)}
                                                                >
                                                                    <i className="icon-open-eye-1"></i>
                                                                </button>
                                                                <Tooltip id="view-tooltip" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="product-info">
                                                        <h2><Link onClick={ClickHandler} to={`/product-single/${product.slug}`}>{product.title}</Link></h2>
                                                        <div className="rating-product">
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                        </div>
                                                        <div className="price">
                                                            <span className="present-price">${product.price}</span>
                                                            <del className="old-price">${product.delPrice}</del>
                                                            <button
                                                                className="cart-btn"
                                                                data-tooltip-id="cart-tooltip" data-tooltip-content="add to cart"
                                                                data-tooltip-place="left"
                                                                onClick={() => addToCartProduct(product)}
                                                            >
                                                                <i className="icon-cart"></i>
                                                            </button>
                                                            <Tooltip id="cart-tooltip" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div className="row">
                                    {products.length > 0 &&
                                        products.slice(12, 18).map((product, pitem) => (
                                            <div className="col-xl-4 col-lg-6 col-sm-6 col-12" key={pitem}>
                                                <div className="product-item">
                                                    <div className="product-image">
                                                        <img src={product.proImg} alt="" />
                                                        <ul className="tag-wrap">
                                                            {product.badge1 ? <li className={`tag ${product.badgeClass}`}>{product.badge1}</li> : ""}
                                                            {product.badge2 ? <li className={`tag ${product.badgeClass2}`}>{product.badge2}</li> : ""}
                                                        </ul>
                                                        <ul className="info-wrap">
                                                            <li>
                                                                <button
                                                                    data-tooltip-id="wish-tooltip" data-tooltip-content="Add to Wishlist"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => addToWishListProduct(product)}
                                                                >
                                                                    <i className="icon-heart"></i>
                                                                </button>
                                                                <Tooltip id="wish-tooltip" />
                                                            </li>
                                                            <li>
                                                                <button
                                                                    data-tooltip-id="compare-tooltip" data-tooltip-content="Add to Compare"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => addToCompareListProduct(product)}
                                                                >
                                                                    <i className="icon-left-and-right-arrows-1"></i>
                                                                </button>
                                                                <Tooltip id="compare-tooltip" />
                                                            </li>
                                                            <li data-bs-toggle="modal" data-bs-target="#modal-cart-view">
                                                                <button
                                                                    data-tooltip-id="view-tooltip" data-tooltip-content="Quick View"
                                                                    data-tooltip-place="left"
                                                                    onClick={() => handleClickOpen(product)}
                                                                >
                                                                    <i className="icon-open-eye-1"></i>
                                                                </button>
                                                                <Tooltip id="view-tooltip" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="product-info">
                                                        <h2><Link onClick={ClickHandler} to={`/product-single/${product.slug}`}>{product.title}</Link></h2>
                                                        <div className="rating-product">
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                            <i className="icon-star"></i>
                                                        </div>
                                                        <div className="price">
                                                            <span className="present-price">${product.price}</span>
                                                            <del className="old-price">${product.delPrice}</del>
                                                            <button
                                                                className="cart-btn"
                                                                data-tooltip-id="cart-tooltip" data-tooltip-content="add to cart"
                                                                data-tooltip-place="left"
                                                                onClick={() => addToCartProduct(product)}
                                                            >
                                                                <i className="icon-cart"></i>
                                                            </button>
                                                            <Tooltip id="cart-tooltip" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
            <ViewModal
                addToCartProduct={addToCartProduct}
                open={open}
                onClose={handleClose}
                product={state}
            />
        </section>
    );
};

export default LatestProduct;

import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import SectionTitle from "../SectionTitle/SectionTitle";
import TimeCountDown from "../countdown";

const DealProductS3 = ({ products, addToCartProduct }) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="deal-product-section style-3 pt-0">
            <div className="container sortable-gallery">
                <div className="hot-deal-title">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <SectionTitle sTitle={'Deals Of The Day'} />
                        </div>
                        <div className="col-lg-4">
                            <div className="deal-countdown">
                                <TimeCountDown />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="deal-wrap">
                    <div className="row align-items-center">
                        {products.length > 0 &&
                            products.slice(7, 9).map((product, pitem) => (
                                <div className="col col-lg-6 col-12" key={pitem}>
                                    <div className="deal-item">
                                        <div className="deal-image">
                                            <img src={product.proImg} alt="" />
                                        </div>
                                        <div className="product-info">
                                            <h2><Link onClick={ClickHandler} to={`/product-single/${product.slug}`}>{product.title}</Link></h2>
                                            <div className="rating-product">
                                                <i className="icon-star"></i>
                                                <i className="icon-star"></i>
                                                <i className="icon-star"></i>
                                                <i className="icon-star"></i>
                                                <i className="icon-star gray"></i>
                                            </div>
                                            <p>I have been a loyal customer of this auto parts company for years .</p>
                                            <div className="progress">
                                                <div className="progress-bar" data-wow-duration="0.5s"
                                                    data-wow-delay=".3s" role="progressbar" aria-valuenow="66"
                                                    aria-valuemin="0" aria-valuemax="100" style={{ width: "60%" }}>
                                                </div>
                                            </div>
                                            <span>Sold : 4 / 66</span>
                                            <div className="price">
                                                <span className="present-price">${product.price}</span>
                                                <del className="old-price">${product.delPrice}</del>
                                                <button
                                                    className="cart-btn"
                                                    data-tooltip-id="cart-tooltip" data-tooltip-content="add to cart"
                                                    data-tooltip-place="left"
                                                    onClick={() => addToCartProduct(product)}
                                                >
                                                    <i className="icon-cart"></i>
                                                </button>
                                                <Tooltip id="cart-tooltip" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                    </div>
                </div>
            </div>
        </section>
    );
};

export default DealProductS3;

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import Discountbar from '../Discountbar/Discountbar';
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';
import HeaderMiddle from '../HeaderMiddle/HeaderMiddle';

const Header = (props) => {

    const [categoryActive, setcategoryState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="main-header-wrap">
            <Discountbar />
            <HeaderTopbar />
            <header className={`header-area ${props.hclass}`}>
                <HeaderMiddle />
                <div id="header-sticky" className="menu-area">
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3 col-md-7 col-sm-5 col-3">
                                    <div className="header-category-item">
                                        <button className="header-category-toggle-btn" onClick={() => setcategoryState(!categoryActive)}><span>Browse categorys</span> </button>
                                        <div className={`category-shop-item ${categoryActive ? "category-shop-item-toggle" : ""}`}>
                                            <ul id="metis-menu">
                                                <li>
                                                    <Link onClick={ClickHandler} to="/shop"><i className="icon-mask-1"></i> Surgical Mask</Link>
                                                </li>
                                                <li className="header-catagory-item">
                                                    <Link className="menu-down-arrow" to="/shop"><i className="icon-medicine"></i>
                                                        Pharmacy</Link>
                                                    <ul className="header-catagory-single">
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine & Equipment</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Thermometer</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medical Supplies</Link></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link onClick={ClickHandler} to="/shop"><i className="icon-balanced-diet"></i> Nutritions</Link>
                                                </li>
                                                <li className="header-catagory-item">
                                                    <Link className="menu-down-arrow" to="/shop"><i className="icon-medical"></i>
                                                        Medkits</Link>
                                                    <ul className="header-catagory-single">
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine & Equipment</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Thermometer</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medical Supplies</Link></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link onClick={ClickHandler} to="/shop"><i className="icon-medicine2"></i> Medicine</Link>
                                                </li>
                                                <li className="header-catagory-item">
                                                    <Link className="menu-down-arrow" to="/shop"><i className="icon-skincare"></i> Beauty
                                                        Product</Link>
                                                    <ul className="header-catagory-single">
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine & Equipment</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Thermometer</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medical Supplies</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="header-catagory-item">
                                                    <Link className="menu-down-arrow" to="/shop"><i className="icon-stethoscope"></i>
                                                        Equipments</Link>
                                                    <ul className="header-catagory-single">
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medicine & Equipment</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Thermometer</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/shop">Medical Supplies</Link></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link onClick={ClickHandler} to="/shop"><i className="icon-medicine"></i> Medical Supplies</Link>
                                                </li>
                                                <li>
                                                    <Link onClick={ClickHandler} to="/shop"><i className="icon-food"></i> Grocery Product</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-6 col-md-1 col-1 text-right text-xl-right d-none d-lg-block">
                                    <div className="main-menu">
                                        <nav id="mobile-menu">
                                            <ul className="nav">
                                                <li className="has-submenu">
                                                    <Link onClick={ClickHandler} to="/home">Home Pages</Link>
                                                    <ul className="sub-menu">
                                                        <li><Link onClick={ClickHandler} to="/home">Home Page 01</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/home-2">Home Page 02</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/home-3">Home Page 03</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="has-submenu"><Link onClick={ClickHandler} to="/shop">Shop</Link>
                                                    <ul className="sub-menu">
                                                        <li><Link onClick={ClickHandler} to="/shop">Shop Page</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/product-single/Hand-Sanitizer">Shop Single</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/cart">Cart Page</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/wishlist">Wishlist</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/compare">Compare</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/checkout">Checkout</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="has-submenu"><Link onClick={ClickHandler} to="/about">Pages</Link>
                                                    <ul className="sub-menu">
                                                        <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/login">My Account</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/forgot">Forgot Password</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/compare">Compare</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/404">404</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="has-submenu">
                                                    <Link onClick={ClickHandler} to="/home">Blog</Link>
                                                    <ul className="sub-menu">
                                                        <li><Link onClick={ClickHandler} to="/blog">Blog Grid</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-right-sidebar">Blog right sidebar</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
                                                        <li className="third-lavel-menu"><Link onClick={ClickHandler} to="/blog-single/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog
                                                            Single</Link>
                                                            <ul className="sub-menu">
                                                                <li><Link onClick={ClickHandler} to="/blog-single/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog single right sidebar</Link>
                                                                </li>
                                                                <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog single left
                                                                    sidebar</Link></li>
                                                                <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/Mirage-Deep-Dive-Under-anding-Timin-Response">Blog single
                                                                    fullwidth</Link></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-6 text-left">
                                    <div className="header-area-right">
                                        <div className="profile">
                                            <Link onClick={ClickHandler} to="/login">
                                                <i className="icon-user-1"></i>
                                                <span>
                                                    <small>My Account</small>
                                                    <span>Hello, Sign in </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1">
                                    <MobileMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}


export default Header;
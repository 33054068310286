import React from 'react'
import stimg from '../../images/icon/title.png'


const SectionTitle = (props) => {
    return (
        <div className={`heading-title ${props.sClass}`}>
            <h2><i><img src={stimg} alt=""/></i>{props.sTitle}</h2>
        </div>
    )
}

export default SectionTitle;
import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import { connect } from "react-redux";
import { addToCart, addToWishList, addToCompareList } from "../../store/actions/action";
import api from "../../api";
import Hero from '../../components/hero/hero';
import AnimateMarquee from '../../components/AnimateMarquee/AnimateMarquee';
import Categorys from '../../components/Categorys/Categorys';
import OfferBanner from '../../components/OfferBanner/OfferBanner';
import PopularCategories from '../../components/PopularCategories/PopularCategories';
import CtaBanner from '../../components/CtaBanner/CtaBanner';
import LatestProduct from '../../components/LatestProduct/LatestProduct';
import CtaBannerS2 from '../../components/CtaBannerS2/CtaBannerS2';
import DealProduct from '../../components/DealProduct/DealProduct';
import TopAndBestProduct from '../../components/TopAndBestProduct/TopAndBestProduct';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import DailyBestSelling from '../../components/DailyBestSelling/DailyBestSelling';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import CtaBannerS3 from '../../components/CtaBannerS3/CtaBannerS3';
import Testimonial from '../../components/Testimonial/Testimonial';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage = ({ addToCart, addToWishList, addToCompareList }) => {
    const productsArray = api();

    const addToCartProduct = (product, qty = 1) => {
        addToCart(product, qty);
    };
    const addToWishListProduct = (product, qty = 1) => {
        addToWishList(product, qty);
    };
    const addToCompareListProduct = (product, qty = 1) => {
        addToCompareList(product, qty);
    };

    const products = productsArray;
    
    return (
        <Fragment>
            <Navbar/>
            <Hero />
            <AnimateMarquee />
            <Categorys />
            <OfferBanner />
            <PopularCategories
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                addToCompareListProduct={addToCompareListProduct}
                products={products}
            />
            <CtaBanner />
            <LatestProduct 
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                addToCompareListProduct={addToCompareListProduct}
                products={products}
            />
            <CtaBannerS2 />
            <DealProduct 
                addToCartProduct={addToCartProduct}
                products={products}
            />
            <TopAndBestProduct
                products={products}
            />
            <FeaturesSection />
            <DailyBestSelling
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                addToCompareListProduct={addToCompareListProduct}
                products={products}
            />
            <PartnerSection />
            <Testimonial />
            <CtaBannerS3 />
            <BlogSection />
            <Footer /> 
            <Scrollbar />
        </Fragment>
    )
};
export default connect(null, { addToCart, addToWishList, addToCompareList })(HomePage);
import React from "react";
import { Link } from "react-router-dom";

const ProductList = ({ products, addToCartProduct }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="product-list">
      <div className="product-wrap">
        <div className="row align-items-center">
          {products.length > 0 &&
            products.slice(0, 16).map((product, pitem) => (
              <div className="col-xl-6 col-lg-6 col-md-6 col-12" key={pitem}>
                <div className="horizontal-product-item">
                  <div className="product-image">
                    <img src={product.proImg} alt="" />
                  </div>
                  <div className="product-info">
                    <h2><Link onClick={ClickHandler} to={`/product-single/${product.slug}`}>{product.title}</Link></h2>
                    <div className="rating-product">
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star gray"></i>
                    </div>
                    <div className="price">
                      <span className="present-price">${product.price}</span>
                      <del className="old-price">${product.delPrice}</del>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductList;



import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/hero/img-3.png'
import { Slide } from "react-awesome-reveal";
import shape from '../../images/hero/shape2.png'
import shape1 from '../../images/banner/frame-1.png'
import shape2 from '../../images/banner/blur-shape.png'
import shape3 from '../../images/hero/card-bg.png'
import banner1 from '../../images/banner/img-3.png'
import banner2 from '../../images/hero/right-1.png'

const Hero3 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="static-hero-s3">
            <div className="container-fluid">
                <div className="static-hero-wrap">
                    <div className="row">
                        <div className="col-lg-9 left-col">
                            <div className="static-hero-left">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="hero-content">
                                            <Slide direction='up' triggerOnce={'false'}>
                                                <h1>30% </h1>
                                            </Slide>
                                            <Slide direction='up' triggerOnce={'false'}>
                                                <h2>Big Discount</h2>
                                            </Slide>
                                            <Slide direction='up' triggerOnce={'false'}>
                                                <p>I have been a loyal
                                                    customer of this auto parts company for years and I cannot recommend
                                                    them enough. Their extensive selection of high-quality parts and
                                                    accessories.</p>
                                            </Slide>
                                            <Slide direction='up' triggerOnce={'false'}>
                                                <Link onClick={ClickHandler} to="/shop" className="btn-style-1">Shop Now</Link>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <Slide direction='up' triggerOnce={'false'}>
                                            <div className="hero-img">
                                                <img src={hero1} alt="" />
                                            </div>
                                        </Slide>
                                    </div>
                                </div>
                                <div className="right-shape">
                                    <img src={shape} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col">
                            <div className="static-hero-right">
                                <Slide direction='top' triggerOnce={'false'}>
                                    <div className="offer-banner-item red-banner">
                                        <div className="offer-image">
                                            <img src={banner1} alt="" />
                                        </div>
                                        <div className="text">
                                            <h4>Upto 25% Off</h4>
                                            <h2>Hand <small>sanitizer</small></h2>
                                            <p>I have been a loyal customer of this auto parts company for years.</p>
                                            <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                                        </div>
                                        <div className="frame"><img src={shape1} alt="" /></div>
                                        <div className="blur-shape"><img src={shape2} alt="" /></div>
                                    </div>
                                </Slide>
                                <Slide direction='right' triggerOnce={'false'}>
                                    <div className="offer-banner-item blue-card">
                                        <div className="offer-image">
                                            <img src={banner2} alt="" />
                                        </div>
                                        <div className="text">
                                            <span>-10% OFF</span>
                                            <h2>Hand <small>sanitizer</small></h2>
                                            <p>I have been a loyal customer.</p>
                                            <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                                        </div>
                                        <div className="frame"><img src={shape3} alt="" /></div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero3;
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.jpg'
import ts2 from '../../images/testimonial/img-2.jpg'
import ts3 from '../../images/testimonial/img-3.jpg'
import lImg from '../../images/testimonial/left-img.png'


const testimonial = [
    {
        id: '01',
        tImg: ts1,
        Des: "Transforming ideas into user-fProin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus.",
        Title: 'Darlene Robertson',
        Sub: "Web design",
    },
    {
        id: '02',
        tImg: ts2,
        Des: "Transforming ideas into user-fProin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus.",
        Title: 'Robert Miller',
        Sub: "UX/UI Designer",
    },
    {
        id: '03',
        tImg: ts3,
        Des: "Transforming ideas into user-fProin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus.",
        Title: 'Ken William',
        Sub: "Programmer",
    }
]

const Testimonial = (props) => {

    const settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };


    return (
        <section className="testimonial-section separator-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="testimonial-left-img">
                            <img src={lImg} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-items testimonial-active">
                            <Slider {...settings}>
                                {
                                    testimonial.map((tesmnl, tsm) => (
                                        <div className="testimonial-item" key={tsm}>
                                            <div className="testimonial-text">
                                                <p>“{tesmnl.Des}”</p>
                                                <div className="testimonial-avatar">
                                                    <img src={tesmnl.tImg} alt="" />
                                                </div>
                                                <div className="ratting-wrap">
                                                    <ul className="ratting">
                                                        <li><i className="icon-star"></i></li>
                                                        <li><i className="icon-star"></i></li>
                                                        <li><i className="icon-star"></i></li>
                                                        <li><i className="icon-star"></i></li>
                                                        <li><i className="icon-star"></i></li>
                                                    </ul>
                                                    <ul className="count">
                                                        <li>4.9 / </li>
                                                        <li>5.00</li>
                                                    </ul>
                                                </div>
                                                <div className="testimonial-text-btm">
                                                    <h3>{tesmnl.Title}</h3>
                                                    <span>{tesmnl.Sub}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { addToCart, addToWishList, addToCompareList } from "../../store/actions/action";
import api from "../../api";
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer';
import FilterSidebar from "../../components/FilterSidebar";
import FilterAllProduct from "../../components/FilterAllProduct";

const ShopPage = ({ addToCart, addToWishList, addToCompareList }) => {

    const productsArray = api();

    const [filter, setFilter] = useState({
        price: "",
        size: "",
        category: "",
        brand: "",
    });

    const priceChangeHandler = ({ target: { name, value } }) => {
        const val = typeof value === "string" ? JSON.parse(value) : value;
        setFilter({ ...filter, [name]: val });
    };

    const changeHandler = ({ target: { name, value } }) => {
        setFilter({ ...filter, [name]: value });
    };

    const priceFIlter = (price) => {
        if (filter.price === "") {
            return true;
        } else if (filter.price.max && filter.price.min) {
            return price <= filter.price.max && price >= filter.price.min;
        } else if (filter.price.min) {
            return price >= filter.price.min;
        } else {
            return false;
        }
    };

    const addToCartProduct = (product) => {
        addToCart(product, 1, filter.category, filter.size);
    };

    const products = productsArray
        .filter((el) => priceFIlter(el.price))
        .filter((el) => (filter.size ? el.size === filter.size : true))
        .filter((el) => (filter.category ? el.category === filter.category : true))
        .filter((el) => (filter.brand ? el.brand === filter.brand : true));

    const addToWishListProduct = (product, qty = 1) => {
        addToWishList(product, qty);
    };
    const addToCompareListProduct = (product, qty = 1) => {
        addToCompareList(product, qty);
    };
    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Shop'} pagesub={'Shop'} />
            <div className="shop-section">
                <div className="container">
                    <div className="row">
                        <FilterSidebar
                            filter={filter}
                            priceChangeHandler={priceChangeHandler}
                            addToCompareListProduct={addToCompareListProduct}
                            changeHandler={changeHandler}
                        />
                        <FilterAllProduct
                            addToCartProduct={addToCartProduct}
                            addToWishListProduct={addToWishListProduct}
                            addToCompareListProduct={addToCompareListProduct}
                            products={products}
                        />
                    </div>
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default connect(null, { addToCart, addToWishList, addToCompareList })(ShopPage);
import React from "react";

const prices = [
  {
    id: 1,
    min: 50,
    max: 100,
  },
  {
    id: 2,
    min: 100,
    max: 200,
  },
  {
    id: 3,
    min: 200,
    max: null,
  },
];

const sizes = ["small", "medium", "large"];
const brands = ["Squarely", "Pekos", "Pharma"];
const categorys = ["Medkits", "Surgical Mask", "Safety Guard", "Pharmacy",];

const FilterSidebar = ({ filter, changeHandler, priceChangeHandler }) => {
  return (
    <div className="col-lg-4">
      <div className="shop-filter-wrap">
        <div className="filter-item">
          <div className="shop-filter-item category-widget">
            <h2>Shop By Category</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All Category
                  <input
                    checked={filter.category === ""}
                    type="radio"
                    value=""
                    onChange={changeHandler}
                    name="category"
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {categorys.map((category, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    {category}
                    <input
                      checked={filter.category === category}
                      type="radio"
                      value={category}
                      onChange={changeHandler}
                      name="category"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="filter-item">
          <div className="shop-filter-item category-widget">
            <h2>Shop By Brand</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All brand
                  <input
                    checked={filter.brand === ""}
                    type="radio"
                    value=""
                    onChange={changeHandler}
                    name="brand"
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {brands.map((brand, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    {brand}
                    <input
                      checked={filter.brand === brand}
                      type="radio"
                      value={brand}
                      onChange={changeHandler}
                      name="brand"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="filter-item">
          <div className="shop-filter-item category-widget">
            <h2>Price</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All prices
                  <input
                    type="radio"
                    value={""}
                    checked={!filter.price}
                    name="price"
                    onChange={changeHandler}
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {prices.map((price, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    ${price.min} {price.max ? "- $" : ""}
                    {price.max || " and more"}
                    <input
                      checked={filter.price.id === price.id}
                      type="radio"
                      value={JSON.stringify(price)}
                      onChange={priceChangeHandler}
                      name="price"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="filter-item">
          <div className="shop-filter-item category-widget">
            <h2>Size</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  All Size
                  <input
                    checked={filter.size === ""}
                    type="radio"
                    value=""
                    onChange={changeHandler}
                    name="size"
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {sizes.map((size, i) => (
                <li key={i}>
                  <label className="topcoat-radio-button__label">
                    {size}
                    <input
                      checked={filter.size === size}
                      type="radio"
                      value={size}
                      onChange={changeHandler}
                      name="size"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;

import React from 'react'
import { Link } from 'react-router-dom'
import erimg from '../../images/cta/man.png'
import erimg2 from '../../images/cta/hand.png'
import erimg3 from '../../images/cta/animate-shape.png'


const CtaBannerS4 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="cta-banner-section-s2 separator-padding">
            <div className="container">
                <div className="cta-banner-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-12 offset-lg-2">
                            <div className="cta-banner-text">
                                <div className="small-heading-title">
                                    <span>100% Premium Quality</span>
                                    <h3>New Medical oxygen Meter 2024</h3>
                                    <p>I have been a loyal customer of this auto parts company for years</p>
                                    <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-banner-img">
                <img src={erimg} alt="" />
            </div>
            <div className="hand-img">
                <img src={erimg2} alt="" />
            </div>
            <div className="animate-shape">
                <img src={erimg3} alt="" />
            </div>
        </section>
    )
}

export default CtaBannerS4;
import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import { connect } from "react-redux";
import { addToCart, addToWishList, addToCompareList } from "../../store/actions/action";
import api from "../../api";
import Categorys3 from '../../components/Categorys3/Categorys3';
import Hero3 from '../../components/hero3/hero3';
import LatestProductS2 from '../../components/LatestProductS2/LatestProductS2';
import CtaBanner from '../../components/CtaBanner/CtaBanner';
import DealOfDay from '../../components/DealOfDay/DealOfDay';
import DealProductS3 from '../../components/DealProductS3/DealProductS3';
import NewArrivalProduct from '../../components/NewArrivalProduct/NewArrivalProduct';
import TopSellingProduct2 from '../../components/TopSellingProduct2/TopSellingProduct2';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import CtaBannerS5 from '../../components/CtaBannerS5/CtaBannerS5';
import OfferFeaturesSection from '../../components/OfferFeaturesSection/OfferFeaturesSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import InstagramSection from '../../components/InstagramSection/InstagramSection';
import FooterS2 from '../../components/footerS2/FooterS2';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage3 = ({ addToCart, addToWishList, addToCompareList }) => {
    const productsArray = api();

    const addToCartProduct = (product, qty = 1) => {
        addToCart(product, qty);
    };
    const addToWishListProduct = (product, qty = 1) => {
        addToWishList(product, qty);
    };
    const addToCompareListProduct = (product, qty = 1) => {
        addToCompareList(product, qty);
    };

    const products = productsArray;
    return (
        <Fragment>
            <Navbar2 hclass={'header-style-2'} />
            <Categorys3 />
            <Hero3 />
            <DealOfDay
                products={products}
            />
            <LatestProductS2
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                addToCompareListProduct={addToCompareListProduct}
                products={products}
            />
            <CtaBanner ctaClass={'pt-0'} />
            <NewArrivalProduct
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                addToCompareListProduct={addToCompareListProduct}
                products={products}
            />
            <TopSellingProduct2
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                addToCompareListProduct={addToCompareListProduct}
                products={products}
            />
            <PartnerSection pClass={'style-2'} />
            <CtaBannerS5 />
            <DealProductS3
                addToCartProduct={addToCartProduct}
                products={products}
            />
            <BlogSection />
            <OfferFeaturesSection fClass={"style-2"}/>
            <InstagramSection />
            <FooterS2 />
            <Scrollbar />
        </Fragment>
    )
};
export default connect(null, { addToCart, addToWishList, addToCompareList })(HomePage3);
import React from 'react'
import { Link } from 'react-router-dom'
import erimg from '../../images/cta/left-img.png'


const CtaBanner = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className={`cta-banner-section separator-padding ${props.ctaClass}`}>
            <div className="container">
                <div className="cta-banner-wrap">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-5">
                            <div className="cta-banner-img">
                                <img src={erimg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="cta-banner-text">
                                <span>New Products</span>
                                <h3>Save unto 10% extra enjoy FREE delivery with PLUS membership</h3>
                                <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaBanner;
import React from 'react'
import { Link } from 'react-router-dom'
import erimg from '../../images/cta/left-img6.png'


const CtaBannerS5 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="cta-banner-section3 style-2 separator-padding">
            <div className="container">
                <div className="cta-banner-wrap">
                    <div className="cta-banner cta-banner-left">

                    </div>
                    <div className="cta-banner cta-banner-right">
                        <div className="cta-banner-img">
                            <img src={erimg} alt=""/>
                        </div>
                        <div className="small-heading-title">
                            <h3>Your Desire and Creat online pharmacy</h3>
                            <p>I have been a loyal customer of this auto parts company for years .</p>
                            <Link onClick={ClickHandler} to="/shop" className="small-btn-style">Shop Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaBannerS5;
import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import icon1 from '../../images/icon/pin.png'


const Contactpage = () => {

    return (
        <section className="contact-page-wrap">
            <div className="contact-page-section separator-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="map">
                                <iframe title='map'
                                    src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-info">
                                <h2>Why choose product?</h2>
                                <p>With this potential discomfort in mind, Aloisia Beauty has developed a highly effective
                                    serum that leads to the same visibly noticeable benefits.</p>
                                <div className="contact-info-item mt-4">
                                    <h3>Kentucky</h3>
                                    <p><i><img src={icon1} alt="" /></i>4517 Washington Ave. Manchester,
                                        Kentucky 39495</p>
                                </div>
                                <div className="contact-info-item">
                                    <h3>New Mexico</h3>
                                    <p><i><img src={icon1} alt="" /></i>4140 Parker Rd. Allentown, New
                                        Mexico 31134</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-page separator-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="contact-area-wrapper">
                                <div className="contact-form-area">
                                    <h2>Have You any Suggestion or Queries?</h2>
                                    <p>With this potential discomfort in mind, Aloisia Beauty has developed a highly effective serum that leads to the same visibly noticeable benefits.</p>
                                   <ContactForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Contactpage;

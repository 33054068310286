import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Link } from 'react-router-dom'

const Product = ({ item, addToCart }) => {

  const [qty, setQty] = useState(1);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  }

  return (
    <div className="product-details">
      <div className="row">
        <div className="col-lg-5">
          <div className="product-single-img">
            <Zoom>
              <img src={item.proImg ? item.proImg : ''} alt="products" />
            </Zoom>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="product-single-content">
            <h5>{item.title}</h5>
            <ul className="rating">
              <li><i className="icon-star" aria-hidden="true"></i></li>
              <li><i className="icon-star" aria-hidden="true"></i></li>
              <li><i className="icon-star" aria-hidden="true"></i></li>
              <li><i className="icon-star" aria-hidden="true"></i></li>
              <li><i className="icon-star" aria-hidden="true"></i></li>
            </ul>
            <h6>${item.price}</h6>
            <p>I have been a loyal customer of this auto parts company for years and I cannot
              recommend them enough. Their extensive selection of high-quality parts and
              accessories.</p>
            <div className="product-filter-item color">
              <div className="color-name">
                <span>Color :</span>
                <ul>
                  <li className="color1"><input id="a1" type="radio" name="color" value="30" />
                    <label htmlFor="a1" ></label>
                  </li>
                  <li className="color2"><input id="a2" type="radio" name="color" value="30" />
                    <label htmlFor="a2" ></label>
                  </li>
                  <li className="color3"><input id="a3" type="radio" name="color" value="30" />
                    <label htmlFor="a3" ></label>
                  </li>
                  <li className="color4"><input id="a4" type="radio" name="color" value="30" />
                    <label htmlFor="a4" ></label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="product-filter-item color filter-size">
              <div className="color-name">
                <span>Size :</span>
                <ul>
                  <li className="color"><input id="wa1" type="radio" name="size" value="30" />
                    <label htmlFor="wa1">S</label>
                  </li>
                  <li className="color"><input id="wa2" type="radio" name="size" value="30" />
                    <label htmlFor="wa2">M</label>
                  </li>
                  <li className="color"><input id="wa3" type="radio" name="size" value="30" />
                    <label htmlFor="wa3">L</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="pro-single-btn">
              <b>Quantity : </b>
              <Grid className="quantity cart-plus-minus">
                <Button
                  className="dec qtybutton"
                  onClick={() => setQty(qty <= 1 ? 1 : qty - 1)}
                >
                  -
                </Button>
                <input
                  value={qty}
                  onChange={() => setQty(qty)}
                  type="text"
                />
                <Button
                  className="inc qtybutton"
                  onClick={() => setQty(qty + 1)}
                >
                  +
                </Button>
              </Grid>
              <button
                className="btn-style-1"
                onClick={() => addToCart(item, qty)}
              >
                Add to cart
              </button>
              <Link onClick={ClickHandler} to="/wishlist"><i className="icon icon-heart"></i></Link>
              <Link onClick={ClickHandler} to="/compare"><i className="icon icon-svgexport-4"></i></Link>
            </div>
            <div className="stock">
              <ul>
                <li>In stock</li>
                <li>SKU: 218967</li>
              </ul>
            </div>
            <div className="category">
              <ul>
                <li><b>Categories:</b></li>
                <li>Medicine, Safety Guard</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;

import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import dImg1 from '../../images/deal/deal-left.png'
import dImg2 from '../../images/deal/round-shape.png'
import dImg3 from '../../images/deal/round-shape2.png'


const DealProductS2 = ({ products, addToCartProduct }) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="deal-product-section style-2">
            <div className="container sortable-gallery">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="deal-left">
                            <div className="deal-img">
                                <img src={dImg1} alt=""/>
                            </div>
                            <div className="deal-text">
                                <h3>Save Up To 60% Speakers</h3>
                                <b>$168.99</b>
                                <p>Up To 70% Off & Free Shipping</p>
                                <Link onClick={ClickHandler} to="/shop" className="small-btn-style primary">Shop Now</Link>
                            </div>
                            <div className="round-shape-1">
                                <img src={dImg2} alt=""/>
                            </div>
                            <div className="round-shape-2">
                                <img src={dImg3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="deal-wrap">
                            {products.length > 0 &&
                                products.slice(7, 9).map((product, pitem) => (
                                    <div className="deal-item" key={pitem}>
                                        <div className="deal-image">
                                            <img src={product.proImg} alt="" />
                                        </div>
                                        <div className="product-info">
                                            <h2><Link onClick={ClickHandler} to={`/product-single/${product.slug}`}>{product.title}</Link></h2>
                                            <div className="rating-product">
                                                <i className="icon-star"></i>
                                                <i className="icon-star"></i>
                                                <i className="icon-star"></i>
                                                <i className="icon-star"></i>
                                                <i className="icon-star gray"></i>
                                            </div>
                                            <p>I have been a loyal customer of this auto parts company for years .</p>
                                            <div className="progress">
                                                <div className="progress-bar" data-wow-duration="0.5s"
                                                    data-wow-delay=".3s" role="progressbar" aria-valuenow="66"
                                                    aria-valuemin="0" aria-valuemax="100" style={{ width: "60%" }}>
                                                </div>
                                            </div>
                                            <span>Sold : 4 / 66</span>
                                            <div className="price">
                                                <span className="present-price">${product.price}</span>
                                                <del className="old-price">${product.delPrice}</del>
                                                <button
                                                    className="cart-btn"
                                                    data-tooltip-id="cart-tooltip" data-tooltip-content="add to cart"
                                                    data-tooltip-place="left"
                                                    onClick={() => addToCartProduct(product)}
                                                >
                                                    <i className="icon-cart"></i>
                                                </button>
                                                <Tooltip id="cart-tooltip" />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DealProductS2;

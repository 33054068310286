import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/hero/img-2.png'
import shape from '../../images/hero/shape.png'
import { Slide } from "react-awesome-reveal";

const Hero2 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="static-hero-s2">
            <div className="container">
                <div className="static-hero-wrap">
                    <div className="static-hero-left">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-lg-5">
                                <div className="hero-content">
                                    <Slide direction='up' triggerOnce={'false'}>
                                        <span>100% Premium Quality</span>
                                    </Slide>
                                    <Slide direction='up' triggerOnce={'false'}>
                                        <h2>New Antibacterial Surgical Mask</h2>
                                    </Slide>
                                    <Slide direction='up' triggerOnce={'false'}>
                                        <p>when unknown printer took a galley type scramble</p>
                                    </Slide>
                                    <Slide direction='up' triggerOnce={'false'}>
                                        <ul>
                                            <li>$50.00 -</li>
                                            <li> $62.00</li>
                                        </ul>
                                    </Slide>
                                    <Slide direction='up' triggerOnce={'false'}>
                                        <Link onClick={ClickHandler} to="/shop" className="btn-style-1">Shop Now</Link>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                        <div className="blur-shape">
                            <svg xmlns="http://www.w3.org/2000/svg" width="586" height="586"
                                viewBox="0 0 586 586" fill="none">
                                <g filter="url(#filter0_f_98_52)">
                                    <circle cx="293" cy="293" r="75" fill="#064ACB" />
                                </g>
                                <defs>
                                    <filter id="filter0_f_98_52" x="0.600006" y="0.600006" width="584.8"
                                        height="584.8" filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                            result="shape" />
                                        <feGaussianBlur stdDeviation="108.7"
                                            result="effect1_foregroundBlur_98_52" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <Slide direction='right' triggerOnce={'false'}>
                <div className="hero-img">
                    <img src={hero1} alt="" />
                    <div className="save-wrap">
                        <div className="save-inner">
                            <p>SAVE 80% OFF</p>
                        </div>
                    </div>
                </div>
            </Slide>
            <div className="p-shape">
                <img src={shape} alt="" />
            </div>
        </section>
    )
}

export default Hero2;
// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";
import blogImg7 from "../images/blog/img-7.jpg";
import blogImg8 from "../images/blog/img-8.jpg";

import blogSingleImg1 from "../images/blog-single/single-1.jpg";
import blogSingleImg2 from "../images/blog-single/single-2.jpg";
import blogSingleImg3 from "../images/blog-single/single-3.jpg";
import blogSingleImg4 from "../images/blog-single/single-4.jpg";
import blogSingleImg5 from "../images/blog-single/single-5.jpg";
import blogSingleImg6 from "../images/blog-single/single-6.jpg";
import blogSingleImg7 from "../images/blog-single/single-7.jpg";
import blogSingleImg8 from "../images/blog-single/single-8.jpg";



const blogs = [
    {
        id: '1',
        title: 'Mirage Deep Dive Under anding Timin Response',
        slug:'Mirage-Deep-Dive-Under-anding-Timin-Response',
        screens: blogImg1,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Loura',
        create_at: '25 Sep 2024',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        day:'28',
        month:'AUGUST',
        blClass:'format-standard-image',
        animation:'1200',
    },
    {
        id: '2',
        title: 'Upload prescription and we will deliver your medicines',
        slug:'Upload-prescription-and-we-will-deliver-your-medicines',
        screens: blogImg2,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'David',
        create_at: '23 Sep 2024',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        day:'25',
        month:'March',
        blClass:'format-standard-image',
        animation:'1400',
    },
    {
        id: '3',
        title: 'The Importance of Process Workflows and Stains in Positive',
        slug:'The-Importance-of-Process-Workflows-and-Stains-in-Positive',
        screens: blogImg3,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Jenefer',
        create_at: '21 Sep 2024',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        day:'23',
        month:'Sep',
        blClass:'format-video',
        animation:'1600',
    },
    {
        id: '4',
        title: 'In our laboratory, if a gram stain result is not entered',
        slug:'In-our-laboratory-if-a-gram-stain-result-is-not-entered',
        screens: blogImg4,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'David',
        create_at: '23 Sep 2024',
        blogSingleImg:blogSingleImg4, 
        comment:'80',
        day:'25',
        month:'March',
        blClass:'format-standard-image',
        animation:'1400',
    },
    {
        id: '5',
        title: 'Mirage Deep Dive Under anding Timin Response',
        slug:'Mirage-Deep-Dive-Under-anding-Tim-Response',
        screens: blogImg5,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Loura',
        create_at: '25 Sep 2024',
        blogSingleImg:blogSingleImg5, 
        comment:'35',
        day:'28',
        month:'AUGUST',
        blClass:'format-standard-image',
        animation:'1200',
    },
    {
        id: '2',
        title: 'Upload prescription and we will deliver your medicines',
        slug:'Upload-prescription-and-we-wi-deliver-your-medicines',
        screens: blogImg6,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'David',
        create_at: '23 Sep 2024',
        blogSingleImg:blogSingleImg6, 
        comment:'80',
        day:'25',
        month:'March',
        blClass:'format-standard-image',
        animation:'1400',
    },
    {
        id: '3',
        title: 'The Importance of Process Workflows and Stains in Positive',
        slug:'The-Importance-of-Process-Workows-and-Stains-in-Positive',
        screens: blogImg7,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Jenefer',
        create_at: '21 Sep 2024',
        blogSingleImg:blogSingleImg7,
        comment:'95',
        day:'23',
        month:'Sep',
        blClass:'format-video',
        animation:'1600',
    },
    {
        id: '4',
        title: 'In our laboratory, if a gram stain result is not entered',
        slug:'In-our-laboratory-if-a-gra-result-is-not-entered',
        screens: blogImg8,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'David',
        create_at: '23 Sep 2024',
        blogSingleImg:blogSingleImg8, 
        comment:'80',
        day:'25',
        month:'March',
        blClass:'format-standard-image',
        animation:'1400',
    },
];
export default blogs;
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'

const Category = [
    {
        id: '1',
        icon: 'icon-skincare',
        title: "Beauty",
    },
    {
        id: '2',
        icon: 'icon-food',
        title: "Grocery",
    },
    {
        id: '3',
        icon: 'icon-medicine',
        title: "Pharmacy",
    },
    {
        id: '4',
        icon: 'icon-mask-1',
        title: "Surgical Mask",
    },
    {
        id: '5',
        icon: 'icon-medical',
        title: "Medkits",
    },
    {
        id: '6',
        icon: 'icon-food',
        title: "Medicine",
    },
    {
        id: '7',
        icon: 'icon-stethoscope',
        title: "Stethoscope",
    },
    {
        id: '8',
        icon: 'icon-product',
        title: "Gift Box",
    }
]

const Categorys3 = (props) => {

    const settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                arrows: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 1,
            }
        },
        ]
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <section className="featured-section style-3">
            <div className="container-fluid">
                <div className="featured-category-slider featured-category-slider-active">
                    <Slider {...settings}>
                        {
                            Category.map((category, cat) => (
                                <div className="featured-item" key={cat}>
                                    <div className="icon">
                                        <i className={category.icon}></i>
                                    </div>
                                    <div className="text">
                                        <h2><Link onClick={ClickHandler} to={'/shop'}>{category.title}</Link></h2>
                                        <span>{category.id} Product</span>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default Categorys3;